import vtxDomReady from '../common/helpers';

vtxDomReady(() => {
  /* TRANSITION 02 */
  const btnsTransition02 = document.querySelectorAll(
    '.wp-transition-button.is-style-transition-002'
  );

  if (0 < btnsTransition02.length) {
    btnsTransition02.forEach(function (btn) {
      const btnTxtAttribute = btn.innerHTML;
      const btnSpan =
        '<span>' +
        btnTxtAttribute +
        "</span><div class='marquee' aria-hidden='true'><div class='marquee__inner'><span>" +
        btnTxtAttribute +
        '</span><span>' +
        btnTxtAttribute +
        '</span><span>' +
        btnTxtAttribute +
        '</span><span>' +
        btnTxtAttribute +
        '</span></div></div>';
      btn.innerHTML = btnSpan;
    });
  }

  /* TRANSITION 004 */
  const btnsTransition04 = document.querySelectorAll(
    '.wp-transition-button.is-style-transition-004'
  );

  if (0 < btnsTransition04.length) {
    btnsTransition04.forEach((btn) => {
      btn.addEventListener('mouseenter', (event) => {
        const x = event.offsetX / btn.offsetWidth;
        const y =
          (event.offsetY + (btn.offsetWidth - btn.offsetHeight) / 2) /
          btn.offsetWidth;

        btn.style.setProperty('--x', 100 * (x - 0.5) + '%');
        btn.style.setProperty('--y', 100 * (y - 0.5) + '%');
      });
      btn.addEventListener('mouseleave', (event) => {
        const x = event.offsetX / btn.offsetWidth;
        const y =
          (event.offsetY + (btn.offsetWidth - btn.offsetHeight) / 2) /
          btn.offsetWidth;

        btn.style.setProperty('--x', 100 * (x - 0.5) + '%');
        btn.style.setProperty('--y', 100 * (y - 0.5) + '%');
      });
    });
  }

  /* TRANSITION ARROW 2 */
  const btnsArrow2 = document.querySelectorAll(
    '.wp-transition-button.is-style-transition-arrow-002'
  );

  if (0 < btnsArrow2.length) {
    btnsArrow2.forEach((button) => {
      const btnText = button.textContent;
      const btnInnerContent = document.createElement('span');
      btnInnerContent.classList.add('btn__text');
      btnInnerContent.innerHTML =
        '<span class="text-main">' +
        btnText +
        '</span><span class="text-hover" aria-hidden="true">' +
        btnText +
        '</span>';
      button.replaceChild(btnInnerContent, button.firstChild);
    });
  }
});
