import domReady from '@wordpress/dom-ready';

domReady(() => {
  /* TRANSITION 001 */
  const singleLinksTransition001 = document.querySelectorAll(
    '.ui-transition-single-links.is-style-transition-001 .word-wrapper'
  );

  if (0 < singleLinksTransition001.length) {
    singleLinksTransition001.forEach((link) => {
      const words = link.innerText.split(' ');
      link.textContent = '';
      words.forEach((word, key) => {
        const wordHTML = document.createElement('span');
        const chars = word.split('');
        wordHTML.classList.add('word');
        chars.forEach((char) => {
          const charHTML = document.createElement('span');
          const initialHTML = document.createElement('span');
          const hoverHTML = document.createElement('span');
          charHTML.classList.add('word__char');
          initialHTML.classList.add('word__char__initial-state');
          hoverHTML.classList.add('word__char__hover-state');
          initialHTML.textContent = char;
          hoverHTML.textContent = char;
          charHTML.appendChild(initialHTML);
          charHTML.appendChild(hoverHTML);
          wordHTML.appendChild(charHTML);
        });
        // add space
        if (key < words.length - 1) {
          const spaceHTML = document.createElement('span');
          spaceHTML.classList.add('word__char');
          spaceHTML.classList.add('word__char--space');
          spaceHTML.textContent = ' ';
          wordHTML.appendChild(spaceHTML);
        }
        // add chars to link
        link.appendChild(wordHTML);
      });
    });
  }
});
